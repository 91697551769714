import React from 'react';

import { useSpring, animated, config } from 'react-spring';
import { useScrollPosition } from './scroll-position';

const WheelItem = ( { hover, title, articleCount, description } ) => {

    const scrollPosition = useScrollPosition();

    const { transform } = useSpring( {
        transform: `translate3d(0, ${scrollPosition.y / 1.5 }px, 0)`,
        config: config.fast
    } );

    return <animated.div className={`wheel-hover ${hover ? 'hovered' : ''} container-fluid hidden-xs`} style={{ transform }}>
        <div className="row">
            <div className="col-12">
                <h1 className="title">{title}<sup>{articleCount}</sup></h1>
            </div>
            <div className="col-5">
                <p className="small">{description}</p>
            </div>
        </div>
    </animated.div>;

};

export default WheelItem;