import React, { useEffect, useContext } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';
import Feedback from '../components/feedback';
import Marquee from '../components/marquee';
import Button from '../components/button';
import Wheel from '../components/wheel';
import Card from '../components/card';
import Page from '../components/page';
import SEO from '../components/seo';

import { PageWrapperContext } from '../components/page-wrapper';
import { transition } from '../components/config';

import styles from '../styles/pages/index.module.scss';

const IndexPage = ( { transitionStatus, location } ) => {

    // TODO: Animate sections when entering viewport

    const pageWrapperContext = useContext( PageWrapperContext );

    useEffect( () => {
        pageWrapperContext.setPageClass( 'page-index' );
    }, [] );

    return <Page transitionStatus={transitionStatus}>
        <SEO title="Home" description="Our rapidly changing world and interconnected societies are in need of journalists that collaborate across borders. But how do you, as a journalist, get started? What tools can you use, and how can you collaborate effectively across borders?" image={'/images/opengraph/general.jpg'} />
        <section className={'container-fluid'}>
            <div className={`row ${styles.about}`}>
                <div className="col col-5 col-sm-6 col-xs-12">
                    <p>Our rapidly changing world and interconnected societies are in need of journalists that collaborate across borders. But how do you, as a journalist, get started? What tools can you use, and how can you collaborate effectively across borders?</p>
                    <TransitionLink to='/about' {...transition} className="hide-xs" aria-hidden="true">
                        <Button small>More about the playbook</Button>
                    </TransitionLink>
                </div>
                <div className="col col-5 col-sm-6 col-xs-12">
                    <p>This playbook is a guide drawn from the experiences of a pioneering group of cross-border journalists. It is only the beginning: we would love to hear about your experiences and ideas on cross-border journalism in the feedback-section below.</p>
                    <TransitionLink to='/about' {...transition} className="hide-sm">
                        <Button small>More about the playbook</Button>
                    </TransitionLink>
                </div>
            </div>
        </section>
        <Wheel />
        <section className="container-fluid">
            <div className={`row ${styles.teamAndMethods}`}>
                <div className={`${styles.gradient}`}></div>
                <div className="col-12">
                    <h2>Our playbook follows the flow of Brigitte Alfter’s ‘cross-border wheel.’ It visualises how a cross-border project does not necessarily have a fixed starting point and how each cross-border project naturally flows into a possible new project. Explore the wheel and jump into a section that might be of help in your borderless journey.</h2>
                    <TransitionLink to='/team' {...transition}>
                        <Button>More about the team</Button>
                    </TransitionLink>
                </div>
            </div>
        </section>
        <section className={`${styles.editorsPickMarquee}`}>
            <Marquee speed="3s"><h3>Editors Pick</h3></Marquee>
        </section>
        <section className="container-fluid">
            <div className={`row ${styles.editorsPick}`}>
                <Card
                    title="Information in the Slow Lane."
                    description="Aidan White, the founder of the Ethical Journalism Network, in conversation with Ada Petriczko"
                    url="/research-team/ethical-dilemmas/interview-aidan-white"
                    buttonText="Read article"
                />
                <Card
                    title="Cross-disciplinary Journalism"
                    description="Is the way we think about journalism outdated? In this series, Qian Sun and Jelena Prtoric explore the potential of cross-disciplinary journalism."
                    url="/finding-stories/cross-disciplinary-journalism"
                    buttonText="Listen podcast"
                />
                <Card
                    title="How to develop your idea?"
                    description="You are now embarking on a journey into cross-border journalism. But how do you initiate the brainstorm? And what challenges might you encounter?"
                    url="/finding-stories/how-to-develop-an-idea"
                    buttonText="Read article"
                />
            </div>
        </section>
        <Feedback />
    </Page>;
};

export default IndexPage;
