import React, { useEffect, useRef, useState, useContext } from 'react';

import WheelItem from './wheel-item';
import WheelHover from './wheel-hover';

import { useInView } from 'react-intersection-observer';
import { PageWrapperContext } from './page-wrapper';
import { useSpring, config } from 'react-spring';
import { useScrollData } from './scroll-data';
import { useWindowSize } from './window-size';
import { useData } from './data';

import '../styles/components/wheel.scss';

const Wheel = () => {

    const pageWrapperContext = useContext( PageWrapperContext );

    const windowSize = useWindowSize();
    const scroll = useScrollData();

    const mounted = useRef();
    const frameRef = useRef();
    const itemsRef = useRef( [] );

    const { ref, inView } = useInView( {
        threshold: 0,
    } );


    const { chapters } = useData();

    const [ hoverStatus, setHoverStatus ] = useState( { hover: false, title: '', articleCount: null, description: '' } );
    const [ wheelRadius, setWheelRadius ] = useState( 0 );
    const [ wheelAngle, setWheelAngle ] = useState( 0 );
    const [ wheelScale, setWheelScale ] = useState( wheelRadius );

    const { radius } = useSpring( {
        radius: wheelRadius,
        config: config.slow
    } );

    const { scale } = useSpring( {
        scale: 1 - ( ( scroll.position.y / ( pageWrapperContext.pageHeight - windowSize.height ) ) / 1.5 ),
        config: config.slow
    } );

    const { speed } = useSpring( {
        speed: ( hoverStatus.hover ? .05 : .2 ) + ( scroll.speed.y / 1000 ),
        config: config.slow
    } );

    useEffect( () => {

        mounted.current = true;
        frameRef.current = requestAnimationFrame( animate );

        setWheelRadius( .75 );
        
        return () => {
            cancelAnimationFrame( frameRef.current );
            mounted.current = false;
        };

    }, [] );

    useEffect( () => {

        if ( inView ) {
            frameRef.current = requestAnimationFrame( animate );
        } else {
            cancelAnimationFrame( frameRef.current );
        }

    }, [ inView ] );

    useEffect( () => {
        updateItems();
    }, [ wheelAngle ] );

    const animate = () => {

        if ( mounted.current === true ) {

            setWheelAngle( ( prevWheelAngle ) => {
                return ( prevWheelAngle === 360 ) ? 0 : prevWheelAngle + speed.value;
            } );
    
            setWheelScale( ( scale.value > .6 ) ? scale.value : .6 );

            frameRef.current = requestAnimationFrame( animate );
        }

    };

    const addItem = ( data, ref ) => {
        itemsRef.current = [ ...itemsRef.current, createItem( data, ref ) ];
    };

    const createItem = ( data, ref ) => {

        const { index } = data;
        const element = ref.current;

        const radius = wheelRadius * ( windowSize.width / 2 );
        const speed = .3;

        const xcenter = windowSize.width / 2;
        const ycenter = windowSize.width / 2;

        let offset = { x: 0, y: 0 };

        let degree = 360 / 7 * index;
        let angle = wheelAngle + degree;
        let radian = ( angle / 180 ) * Math.PI;

        let x = xcenter + Math.cos( radian ) * radius;
        let y = ycenter + Math.sin( radian ) * radius;

        return { element, radius, speed, xcenter, ycenter, offset, degree, angle, radian, x, y };
    };

    const updateItems = () => {

        itemsRef.current.forEach( ( item ) => {

            item.angle = wheelAngle + item.degree;
            item.radius = radius.value * ( windowSize.width / 2 ) * wheelScale;
            item.radian = ( item.angle / 180 ) * Math.PI;

            item.offset.y = ( scroll.position.y / 10 );

            item.xcenter = windowSize.width / 2;
            item.ycenter = windowSize.width / 2 - item.offset.y;

            item.x = item.xcenter + Math.cos( item.radian ) * item.radius;
            item.y = item.ycenter + Math.sin( item.radian ) * item.radius;

            item.element.setAttribute( 'style', `transform:translate3d(${item.x}px, ${item.y}px, 0) scale3d(${wheelScale}, ${wheelScale}, 1)` );
        } );

    };

    const renderChapters = () => {
        return chapters.map( ( chapter, index ) => {
            return <WheelItem key={index} frontmatter={chapter.frontmatter} articles={chapter.articles} index={index} />;
        } );
    };

    return <WheelContext.Provider value={{ addItem, hoverStatus, setHoverStatus }}>
        <div className="wheel-container" ref={ref}>
            <div className="wheel">
                {renderChapters()}
            </div>
            <WheelHover {...hoverStatus} />
        </div>
    </WheelContext.Provider>;

};

export const WheelContext = React.createContext();
export default Wheel;
