import React, { useEffect, useRef, useContext } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';

import { WheelContext } from './wheel';
import { transition } from './config';
import { ReactSVG } from 'react-svg';

const WheelItem = ( props ) => {

    const context = useContext( WheelContext );
    const ref = useRef();
    
    const linkProps = {
        activeClassName: 'active',
        partiallyActive: true,
    };

    const { frontmatter, index, articles } = props;
    const { title, description, iconPath, slug } = frontmatter;

    useEffect( () => {
        context.addItem( props, ref );
    }, [ ref ] );

    const onMouseEnter = ( title, description ) => {
        context.setHoverStatus( { hover: true, title: title, articleCount: articles.length, description: description } );
    };

    const onMouseLeave = () => {
        context.setHoverStatus( { ...context.hoverStatus, hover: false } );
    };

    return <div className="chapter-container" index={index + 1} ref={ref}>
        <TransitionLink to={slug} {...transition} {...linkProps} onMouseEnter={() => { onMouseEnter( title, description ); }} onMouseLeave={() => { onMouseLeave(); }}>
            <div className={`chapter ${( context.hoverStatus.hover && context.hoverStatus.title === title ) ? 'hover' : ''}`}>
                <div className="icon">
                    <ReactSVG src={iconPath} />
                </div>
                <div className="title">
                    {title}<sup>{articles.length}</sup>
                </div>
            </div>
        </TransitionLink>
    </div>;

};

export default WheelItem;
